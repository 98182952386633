<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    :src="barImage"
    mobile-breakpoint="960"
    app
    mini-variant-width="80"
    width="260"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img
        :gradient="`to bottom, ${barColor}`"
        v-bind="props"
      />
    </template>

    <!-- <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title class="text-uppercase font-weight-regular display-2">
          <span class="logo-mini">{{ $t('ct') }}</span>
          <span class="logo-normal">{{ $t('tim') }}</span>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item> -->

    <!-- NAV BAR TITLE - QRPAY LOGO -->
    <v-list-item class="pl-5 py-2">
      <v-list-item-avatar size="48">
        <v-img :src="require('@/assets/qrpay_icon.png')"></v-img>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title class="text-h4 font-weight-black">
          DDPay Portal
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider class="mb-1" />

    <!-- USER PROFILE -->
    <!-- <v-list dense nav>
      <base-item-group :item="profile" :expanded="false" />
    </v-list> -->
    <v-list two-line>
      <v-list-item-group>
        <v-list-item @click="$router.push('/profile/profile')">
          <v-list-item-avatar size="40">
            <v-img :src="require('@/assets/avatars/006-jupiter.svg')"></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="font-weight-bold text-h5">
              {{ user['displayName'] }}
            </v-list-item-title>
            <v-list-item-subtitle class="font-weight-regular">
              <span v-if="user['merchant']">
                {{ user['merchant']['name'] }}  
              </span>
              <span v-else-if="user['organization']">
                {{ user['organization']['name'] }}
              </span>
              <span v-else>
                (Superadmin)
              </span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>

    <v-divider class="mb-2" />

    <!-- NAV MENU -->
    <v-list
      expand
      nav
    >
      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />

      <template v-for="(item, i) in computedItems">
        <base-item-group
          v-if="item.children && item.hasClearance"
          :key="`group-${i}`"
          :item="item"
        >
          <!--  -->
        </base-item-group>

        <base-item
          v-else-if="item.hasClearance"
          :key="`item-${i}`"
          :item="item"
        />
      </template>

      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />
    </v-list>

    <v-divider class="mb-2" />

  </v-navigation-drawer>
</template>

<script>
  // Utilities
  import {
    mapState,
  } from 'vuex'

  export default {
    name: 'DashboardCoreDrawer',

    props: {
      expandOnHover: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      items: [
        {
          group: '/overview',
          icon: 'mdi-view-dashboard',
          title: 'overview',
          children: [
            {
              icon: 'mdi-chart-box-outline',
              title: 'stats',
              to: 'stats',
            },
            {
              icon: 'mdi-script-text-outline',
              title: 'ddpay-orders',
              to: 'ddpay-orders',
              clearance: 'superadmin'
            },
            {
              icon: 'mdi-script-text-outline',
              title: 'orders',
              to: 'orders',
            }
          ]
        },
        {
          group: '/admin',
          icon: 'mdi-cog',
          title: 'administration',
          clearance: 'manager',
          children: [
            {
              icon: 'mdi-domain',
              title: 'organizations',
              to: 'orgs',
              clearance: 'superadmin'
            },
            {
              icon: 'mdi-store-outline',
              title: 'merchants',
              to: 'merchants',
              clearance: 'admin'
            },
            {
              icon: 'mdi-store-outline',
              title: 'merchantSettings',
              to: 'merchant-settings',
              clearance: [ 'manager' ]
            },
            {
              icon: 'mdi-cash-register',
              title: 'paymentServices',
              to: 'payment-services',
              clearance: 'admin'
            },
            {
              icon: 'mdi-account-tie',
              title: 'administrators',
              to: 'administrators',
              clearance: 'superadmin'
            }
          ]
        },
        {
          group: '/vas',
          icon: 'mdi-chart-bubble',
          title: 'vas',
          children: [
            {
              icon: 'mdi-ticket',
              title: 'qpoints',
              to: 'qpoints'
            },
            {
              icon: 'mdi-hand-heart',
              title: 'donations',
              to: 'donations'
            },
            {
              icon: 'mdi-sale',
              title: 'memberDiscountRecords',
              to: 'member-discount-records'
            }
          ]
        },
        {
          group: '/ach',
          icon: 'mdi-bank',
          title: 'ach',
          clearance: 'superadmin',
          children: [
            {
              icon: 'mdi-account-arrow-left-outline',
              title: 'beneficiaries',
              to: 'beneficiaries',
            },
            {
              icon: 'mdi-bank-transfer-out',
              title: 'remittanceService',
              to: 'remittances',
            },
            {
              icon: 'mdi-view-list',
              title: 'batches',
              to: 'batches',
            },
            {
              icon: 'mdi-bank-transfer',
              title: 'transfer',
              to: 'transfer',
            },
            // {
            //   icon: 'mdi-receipt-text',
            //   title: 'invoiceService',
            //   to: 'invoices',
            // },
            // {
            //   icon: 'mdi-bank-transfer-in',
            //   title: '回檔',
            //   to: 'transfer-back',
            // },
            
          ]
        },
        {
          group: '/cardpay',
          icon: 'mdi-account-credit-card',
          title: 'cardpay',
          clearance: 'superadmin',
          children: [
            {
              icon: 'mdi-store-outline',
              title: 'sub-merchants',
              to: 'merchants',
            },
            {
              icon: 'mdi-script-text-outline',
              title: 'orders',
              to: 'orders',
            },
            {
              icon: 'mdi-playlist-minus',
              title: 'surcharges',
              to: 'surcharges',
            }
          ]
        },
        {
          group: '/loginmanagement',
          icon: 'mdi-account-arrow-left-outline',
          title: 'loginmanagement',
          clearance: 'superadmin',
          children: [
            {
              icon: 'mdi-script-text-outline',
              title: 'loginRecords',
              to: 'loginRecords',
            }
          ]
        }
        // {
        //   icon: 'mdi-view-dashboard',
        //   title: 'dashboard',
        //   to: '/',
        // },
        // {
        //   group: '/pages',
        //   icon: 'mdi-image',
        //   title: 'pages',
        //   children: [
        //     {
        //       title: 'pricing',
        //       to: 'pricing',
        //     },
        //     {
        //       title: 'rtl',
        //       to: 'rtl',
        //     },
        //     {
        //       title: 'timeline',
        //       to: 'timeline',
        //     },
        //     {
        //       title: 'login',
        //       to: 'login',
        //     },
        //     {
        //       title: 'register',
        //       to: 'pricing',
        //     },
        //     {
        //       title: 'lock',
        //       to: 'lock',
        //     },
        //     {
        //       title: 'user',
        //       to: 'user',
        //     },
        //     {
        //       title: 'error',
        //       to: '404',
        //     },
        //   ],
        // },
        // {
        //   group: '/components',
        //   icon: 'mdi-view-comfy',
        //   title: 'components',
        //   children: [
        //     {
        //       title: 'multi',
        //       group: '',
        //       children: [
        //         {
        //           title: 'example',
        //           href: '#',
        //         },
        //       ],
        //     },
        //     {
        //       title: 'buttons',
        //       to: 'buttons',
        //     },
        //     {
        //       title: 'grid',
        //       to: 'grid-system',
        //     },
        //     {
        //       title: 'tabs',
        //       to: 'tabs',
        //     },
        //     {
        //       title: 'notifications',
        //       to: 'notifications',
        //     },
        //     {
        //       title: 'icons',
        //       to: 'icons',
        //     },
        //     {
        //       title: 'typography',
        //       to: 'typography',
        //     },
        //   ],
        // },
        // {
        //   group: '/forms',
        //   icon: 'mdi-clipboard-outline',
        //   title: 'forms',
        //   children: [
        //     {
        //       title: 'rforms',
        //       to: 'regular',
        //     },
        //     {
        //       title: 'eforms',
        //       to: 'extended',
        //     },
        //     {
        //       title: 'vforms',
        //       to: 'validation',
        //     },
        //     {
        //       title: 'wizard',
        //       to: 'wizard',
        //     },
        //   ],
        // },
        // {
        //   group: '/tables',
        //   icon: 'mdi-grid',
        //   title: 'tables',
        //   children: [
        //     {
        //       title: 'rtables',
        //       to: 'regular-tables',
        //     },
        //     {
        //       title: 'etables',
        //       to: 'extended-tables',
        //     },
        //     {
        //       title: 'dtables',
        //       to: 'data-tables',
        //     },
        //   ],
        // },
        // {
        //   group: '/maps',
        //   icon: 'mdi-map-marker',
        //   title: 'maps',
        //   children: [
        //     {
        //       title: 'google',
        //       to: 'google-maps',
        //     },
        //     {
        //       title: 'fullscreen',
        //       to: 'full-screen-map',
        //     },
        //   ],
        // },
        // {
        //   icon: 'mdi-widgets',
        //   title: 'widgets',
        //   to: '/widgets',
        // },
        // {
        //   icon: 'mdi-chart-timeline-variant',
        //   title: 'charts',
        //   to: '/charts',
        // },
        // {
        //   icon: 'mdi-calendar-range',
        //   title: 'calendar',
        //   to: '/calendar',
        // },
      ],
    }),

    computed: {
      ...mapState(['barColor', 'barImage']),
      drawer: {
        get () {
          return this.$store.state.drawer
        },
        set (val) {
          this.$store.commit('SET_DRAWER', val)
        },
      },
      computedItems () {
        return this.items.map(this.mapItem)
      },
      user() {
        return this.$store.getters.user;
      },
      profile () {
        let user = this.$store.getters.user;
        return {
          // avatar: true,
          avatar: require('@/assets/avatars/006-jupiter.svg'),
          group: '',

          // title: this.$t('avatar'),
          title: user['displayName'],

          children: [
            {
              href: '',
              title: this.$t('my-profile'),
            },
            {
              to: '',
              title: this.$t('edit-profile'),
            },
            {
              to: '',
              title: this.$t('settings'),
            },
          ],
        }
      },
    },

    watch: {
      '$vuetify.breakpoint.smAndDown' (val) {
        this.$emit('update:expandOnHover', !val)
      },
    },

    methods: {
      mapItem (item) {
        return {
          ...item,
          children: item.children ? item.children.map(this.mapItem) : undefined,
          title: this.$t(item.title),
          hasClearance: this.hasClearance(this.user.level, item)
        }
      },

      hasClearance(level, item) {
        if (Array.isArray(item.clearance))
          return item.clearance.indexOf(level) >= 0;
        else if (typeof item.clearance === 'string') {
          let clearanceMap = {
            'superadmin': 0,
            'admin': 1,
            'manager': 5,
            'employee': 9,
            'default': 99
          };
          return clearanceMap[ level ] <= clearanceMap[ item.clearance ];
        }
        return true;
      }
    },
  }
</script>

<style lang="sass">
  @import '~vuetify/src/styles/tools/_rtl.sass'

  #core-navigation-drawer
    &.v-navigation-drawer--mini-variant
      .v-list-item
        justify-content: flex-start !important

      .v-list-group--sub-group
        display: block !important

    .v-list-group__header.v-list-item--active:before
      opacity: .24

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
          margin-right: 24px
          margin-left: 12px !important

        +rtl()
          margin-left: 24px
          margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px
</style>
